import { render, staticRenderFns } from "./applyFor.vue?vue&type=template&id=b4a67ca0&scoped=true"
import script from "./applyFor.vue?vue&type=script&lang=js"
export * from "./applyFor.vue?vue&type=script&lang=js"
import style0 from "./applyFor.vue?vue&type=style&index=0&id=b4a67ca0&prod&scoped=true&lang=less"


/* normalize component */
import normalizer from "!../../../../../../../../opt/deploy_node_modules/ailaworld/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b4a67ca0",
  null
  
)

export default component.exports