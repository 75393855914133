<template>
  <div class="class-apply-for" v-loading="loading">
    <pre-classification :form_data="{}" ref="preClassificationLabel" class="pre-form" @startUploadOSS="startUploadOSS" @uploadCompleteOSS="uploadCompleteOSS"/>
    <pre-classPay :pay_bol="pay_bol" :code_loading="code_loading" :pay_code="pay_code" @closePay="closePay" @codeStop="codeStop" @codeUpdate="codeUpdate"/>
    <el-dialog title="提示" :visible.sync="dialogVisible" width="300px" :before-close="dialogNo">
      <div>
        <div class="openTitle">
          {{dialogValue}}
        </div>
        <div class="dialog-btn">
          <el-button size="small" @click="dialogNo">否</el-button>
          <el-button type="primary" size="small" v-loading="pay_loading" @click="dialogYes">是</el-button>
        </div>
      </div>
    </el-dialog>
    <el-dialog title="提示" :visible.sync="payDialog" width="300px">
      <div>
        <div class="openTitle">
          提交成功，是否支付？
        </div>
        <div class="dialog-btn">
          <el-button size="small" @click="goToConsole">等待审核</el-button>
          <el-button type="primary" size="small" v-loading="pay_loading" @click="paySave">支付</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import preClassification from "~scb/components/form/preClassification"
import preClassPay from "~scb/components/pay/preClassPay"
import { preClassificationMoneyFormat } from "~scb/plugins/filters"
export default {
  metaInfo: {
    title: "申请预归类",
  },
  components: { preClassification, preClassPay },
  data(){
    return {
      loading: false,
      pay_form: {},//支付数据
      dialogVisible: false,
      dialogValue: '',//弹窗内容
      dialogType: 0,//弹窗类型
      pay_loading: false,//支付loading
      pay_code: {},//支付信息
      pay_bol: false,//支付弹窗
      timeout_set: '',//延时器
      pay_no_buy: false,//判断是否有未支付
      code_loading: false,//二维码
      payDialog:false,//支付及审核弹窗
    }
  },
  async mounted() {
    try {
      this.pay_form = {  
        goods_type: "3",
        payment: "1",
        source: this.PJSource,
        user_id: this.USER_INFO.id,
        belongto: "1",
        goods_amount: "1",
        goods_belongto: "1",
        user_name: this.USER_INFO.name_zh || this.USER_INFO.name_en,
        company_id: this.USER_INFO.company_id,
        company_name: this.USER_INFO.company_name_zh || this.USER_INFO.company_name_en,
      }
      this.loading = true;
      await this.getPayCheckOrder();
      if (this.pay_no_buy) {
        this.dialogValue = '检测到有未支付的预归类！是否去支付';
        this.dialogVisible = true;
        this.dialogType = 0;
      }
      this.loading = false;
    } catch (err) {
      this.loading = false;
    }
  },
  methods: {
    // 获取历史订单
    async getPayCheckOrder(){
      let res = await this.$store.dispatch(
          "API_index/payCheckOrder",
          { 
            company_id: this.USER_INFO.company_id,
            user_id: this.USER_INFO.id
          }
      );
      if (res.success) {
          this.pay_form.amount_total = preClassificationMoneyFormat(res.buy_count);
          this.pay_form.goods_price = preClassificationMoneyFormat(res.buy_count);
          this.pay_form.amount_paid = preClassificationMoneyFormat(res.buy_count,this.USER_INFO.aila_no ? true : false);
          this.pay_no_buy = res.no_pay_count > 0;
      }
    },
    // 开始上传
    startUploadOSS(){
        this.loading = true;
    },
    // 上传完成
    async uploadCompleteOSS(value){
      this.loading = true;
      value.source = this.PJSource;
      value.company_id = this.USER_INFO.company_id;
      value.user_id = this.USER_INFO.id;
      value.product_image = JSON.stringify(value.product_image);
      let res = await this.$store.dispatch(
          "API_company/preClassificationAdd",
          value
      );
      if (res.success) {
        if (value.status === 1) {
          this.pay_form.related_id = res.data;
          // this.dialogValue = '提交成功！是否支付？';
          // this.dialogVisible = true;
          this.payDialog=true
          this.dialogType = 1;
        }else {
          this.dialogValue = '存储成功！是否继续录入？';
          this.dialogVisible = true;
          this.dialogType = 2;
        }
      }
      this.loading = false;
    },
    // 支付
    async payCreate(){
      this.pay_loading = true;
      await this.generateQrCode();
      this.pay_loading = false;
    },
    // 生成二维码
    async generateQrCode(){
      await this.getPayCheckOrder();
      let res = await this.$store.dispatch("API_company/payCourse",this.pay_form);
      if (res.success) {
        this.pay_code = res.data;
        this.dialogVisible = false;
        this.pay_bol = true;
        this.payStatus();
      }
    },
    // 二维码失效
    codeStop(){
      clearTimeout(this.timeout_set);
    },
    // 更新二维码
    async codeUpdate(){
      try {
        this.code_loading = true;
        await this.generateQrCode();
        this.code_loading = false;
      } catch (err) {
        this.code_loading = false;
      }
    },
    // 放弃支付
    dialogNo(){
      if (this.dialogType === 0) {
        this.dialogVisible = false;
      }else if (this.dialogType === 1) {
        this.dialogValue = '是否继续录入？';
        this.dialogVisible = true;
        this.dialogType = 2;
      }else if (this.dialogType === 2) {
        this.$router.push({
          path: "/console/pre/preClassification"
        });
        this.dialogVisible = false;
      }
    },
    // 确定支付
    dialogYes(){
      if (this.dialogType === 0) {
        this.$router.push({
          path: "/console/pre/preClassification"
        });
      }else if (this.dialogType === 2) {
        this.$refs.preClassificationLabel.reset();
        this.dialogVisible = false;
      }else {
        this.payCreate();
      }
    },
    // 取消支付
    closePay(){
      clearTimeout(this.timeout_set);
      this.pay_bol = false;
      this.dialogValue = '是否继续录入？';
      this.dialogVisible = true;
      this.dialogType = 2;
    },
    // 支付状态轮训
    payStatus(){
      this.timeout_set = setTimeout(() => {
        let {related_id, user_id} = this.pay_form;
        this.$store.dispatch("API_index/payClassCheckOrder",{
          user_id,
          related_id,
          goods_type: 3
        }).then(res=>{
          if (res.success) {
            if (res.data.status == 1) {
              clearTimeout(this.timeout_set);
              this.pay_bol = false;
              this.dialogValue = '支付成功是否继续录入？';
              this.dialogVisible = true;
              this.dialogType = 2;
              return
            }
          }
          this.payStatus();
        }).catch(err=>{
          this.payStatus();
        });
      }, 5000);
    },
    goToConsole(){
      this.$router.push({
        path:'/console/pre/preClassification'
      })
    },
    paySave(){
      this.dialogYes()
      this.payDialog=false
    },
  },
  beforeDestroy() {
    clearTimeout(this.timeout_set);
  },
};
</script>

<style scoped lang="less">
.class-apply-for{
    padding: 24px 0 93px;
    background: url("https://attached-file.oss-cn-shanghai.aliyuncs.com/ailaworldImgs/scb/preClassificationBG.png") no-repeat;
}
.pre-form{
    margin: 0 auto;
}
.openTitle {
  font-weight: bold;
}
.dialog-btn{
  text-align: right;
  margin-top: 20px;
}
</style>
